import Container from 'components/Container'
import Cart from 'components/cart'
import CartStyles from 'components/cart/styles'
import React from 'react'
import Helmet from 'react-helmet'
import theme from 'styles/theme'

function CartPage() {
  return (
    <Container css={{ position: 'relative' }}>
      <Helmet title='Cart — OWYN®'>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Cart
        styles={{
          [theme.smallOnly]: {
            ...CartStyles.mobile,
          },
          [theme.medium]: {
            ...CartStyles.desktop,
          },
        }}
      />
    </Container>
  )
}

export default CartPage
